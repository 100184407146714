@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;

  overflow-x: hidden;


  background: #232323;
  color: #cdcdcd;
  font-family: "Avenir Next", "Avenir", sans-serif;
}

#menuToggle {

  display: block;
  position: absolute;
  top: 50px;
  right: 50px;
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;

}

.gray {
  background: #333333;

}

.image-rounded {
  border-radius: 15px 157px 15px;
}
@media screen and (max-width: 600px) {
  .image-rounded {
    border-radius:15px 100px 15px;
  }
}



.do {
  &:after {
    content: "";
    background: rgb(252, 211, 77);
    height: 2px;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: -5px;

  }

}

.right {
margin-top:160px;
  &:before {

  }

  &:after {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    position: absolute;
    top: 50px;
    left: 0;
    transform: translate(-5px, 0);
    background: rgb(252, 211, 77);
    border-radius: 38px;
    transition: all .2s ease-in-out;
    z-index: 99;
  
  }
}

.left {

  &:not(:first-child){
    margin-top: -100px;
  }
  &:before {
    content: "";
    border-left: 1px solid  rgb(252, 211, 77);
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
  }

  &:after {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    position: absolute;
    top: 50px;
    right: 0;

    transform: translate(50%, -55%);
    background: rgb(252, 211, 77);
    border-radius: 38px;
    transition: all .2s ease-in-out;
    z-index: 98;
  }
}


.menu {
  position: absolute;
  right: 20px;
  top: 40px;
  padding: 0;



  .overlay {
    background: rgba(0, 0, 0, 0.73);
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 50;
  }


  .menu-list {
    position: absolute;
    right: -500px;
    top: 50px;
    transition: all 320ms cubic-bezier(0.77, 0.2, 0.05, 1.0);
    text-align: right;
    z-index: 55;

    li {
      min-width: 150px;
      padding: 25px 0;
      font-weight: bold;
      font-size: 24px;
      transition: all 320ms cubic-bezier(0.77, 0.2, 0.05, 1.0);

      &:hover {
        padding-right: 10px;
        opacity: .8;
      }
    }
  }

  input {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    opacity: 0;
    width: 100%;
    height: 100%;
    z-index: 60;

    &:checked + .menu-list {
      right: 20px;
    }


    &:checked ~ .icon span {
      opacity: 1;
      transform: rotate(-45deg) translate(-2px, 2px);
      background: rgb(255, 255, 255);
    }

    &:checked ~ .icon span:nth-last-child(3) {
      opacity: 0;
      transform: rotate(0deg) scale(0.2, 0.2);
    }


    &:checked ~ .icon span:nth-last-child(2) {
      transform: rotate(45deg) translate(-4px, -5px);
    }

  }


  span {
    z-index: 55;
    display: block;
    width: 33px;
    height: 4px;
    margin-bottom: 5px;
    position: relative;

    background: #ffffff;
    border-radius: 3px;

    transform-origin: 4px 2px;

    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
    opacity 0.55s ease;

    &:first-child {
      transform-origin: 0% 0%;
    }

    & :nth-last-child(2) {
      transform-origin: 0% 100%;
    }
  }


}
